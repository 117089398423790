import {
  FETCH_SETTINGS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILED,
  EDIT_SETTINGS,
  CLEAR_SETTINGS_ERROR
} from "../store/types";

import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, set } from "firebase/database";

// Função para aguardar até que settingsRef esteja definido
const waitForSettingsRef = async () => {
  while (!firebase.settingsRef) {
    await new Promise((resolve) => setTimeout(resolve, 10)); // Aguarda 10ms
  }
  return firebase.settingsRef;
};

// Ação para buscar configurações
export const fetchSettings = () => async (dispatch) => {
  const settingsRef = await waitForSettingsRef(); // Aguarda até que settingsRef esteja disponível

  dispatch({
    type: FETCH_SETTINGS,
    payload: null,
  });

  onValue(settingsRef, (snapshot) => {
    if (snapshot.val()) {
      dispatch({
        type: FETCH_SETTINGS_SUCCESS,
        payload: snapshot.val(),
      });
    } else {
      dispatch({
        type: FETCH_SETTINGS_FAILED,
        payload: "Unable to fetch database and settings.",
      });
    }
  });
};

// Ação para editar configurações
export const editSettings = (settings) => async (dispatch) => {
  const settingsRef = await waitForSettingsRef(); // Aguarda até que settingsRef esteja disponível
  dispatch({
    type: EDIT_SETTINGS,
    payload: settings,
  });

  set(settingsRef, settings).then(() => {
    alert(store.getState().languagedata.defaultLanguage.updated);
  }).catch((error) => {
    console.error("Failed to update settings: ", error);
  });
};

// Ação para limpar erros de visualização de configurações
export const clearSettingsViewError = () => (dispatch) => {
  dispatch({
    type: CLEAR_SETTINGS_ERROR,
    payload: null,
  });
};
