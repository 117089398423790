import {
  FETCH_CAR_TYPES,
  FETCH_CAR_TYPES_SUCCESS,
  FETCH_CAR_TYPES_FAILED,
  EDIT_CAR_TYPE,
} from "../store/types";
import store from "../store/store";
import { firebase } from "../config/configureFirebase";
import { onValue, set, remove, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Função para aguardar até que as referências estejam definidas
const waitForCarTypeRefs = async () => {
  while (!firebase.carTypesRef) {
    await new Promise((resolve) => setTimeout(resolve, 10)); // Aguarda 10ms
  }
  return {
    carTypesRef: firebase.carTypesRef
  };
};

// Ação para buscar tipos de carros
export const fetchCarTypes = () => async (dispatch) => {
  const { carTypesRef } = await waitForCarTypeRefs(); // Garante que carTypesRef esteja inicializado

  dispatch({
    type: FETCH_CAR_TYPES,
    payload: null,
  });

  onValue(carTypesRef, (snapshot) => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        return data[i];
      });
      dispatch({
        type: FETCH_CAR_TYPES_SUCCESS,
        payload: arr,
      });
    } else {
      dispatch({
        type: FETCH_CAR_TYPES_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_cars,
      });
    }
  });
};

// Ação para editar tipos de carros
export const editCarType = (cartype, method) => async (dispatch) => {
  const { carTypesRef, carTypesEditRef, carDocImage } = await waitForCarTypeRefs(); // Garante que todas as referências estejam inicializadas

  dispatch({
    type: EDIT_CAR_TYPE,
    payload: { method, cartype },
  });

  if (method === "Add") {
    push(carTypesRef, cartype);
  } else if (method === "Delete") {
    remove(carTypesEditRef(cartype.id));
  } else if (method === "UpdateImage") {
    await uploadBytesResumable(carDocImage(cartype.id), cartype.image);
    let image = await getDownloadURL(carDocImage(cartype.id));
    let data = cartype;
    data.image = image;
    set(carTypesEditRef(cartype.id), data);
  } else {
    set(carTypesEditRef(cartype.id), cartype);
  }
};
