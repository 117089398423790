import {
    FETCH_LANGUAGE,
    FETCH_LANGUAGE_SUCCESS,
    FETCH_LANGUAGE_FAILED,
    EDIT_LANGUAGE
  } from "../store/types";
  import { firebase } from '../config/configureFirebase';
  import { onValue, set, push, remove } from "firebase/database";
  
  // Função para aguardar até que languagesRef esteja definido
  const waitForLanguagesRef = async () => {
    while (!firebase.languagesRef) {
      await new Promise((resolve) => setTimeout(resolve, 10)); // Aguarda 10ms
    }
    return { languagesRef: firebase.languagesRef, languagesEditRef: firebase.languagesEditRef };
  };
  
  // Ação para buscar linguagens
  export const fetchLanguages = () => async (dispatch) => {
    const { languagesRef } = await waitForLanguagesRef(); // Aguarda até que languagesRef esteja disponível
  
    dispatch({
      type: FETCH_LANGUAGE,
      payload: null,
    });
  
    onValue(languagesRef, (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val();
        let defLang = null;
        const arr = Object.keys(data).map((i) => {
          data[i].id = i;
          if (data[i].default) {
            defLang = data[i].keyValuePairs;
          }
          return data[i];
        });
        dispatch({
          type: FETCH_LANGUAGE_SUCCESS,
          payload: {
            defaultLanguage: defLang,
            langlist: arr,
          },
        });
      } else {
        dispatch({
          type: FETCH_LANGUAGE_FAILED,
          payload: "No Languages available.",
        });
      }
    });
  };
  
  // Ação para editar linguagens
  export const editLanguage = (lang, method) => async (dispatch) => {
    const { languagesRef, languagesEditRef } = await waitForLanguagesRef(); // Aguarda até que references estejam disponíveis
  
    dispatch({
      type: EDIT_LANGUAGE,
      payload: { lang, method },
    });
  
    if (method === "Add") {
      push(languagesRef, lang);
    } else if (method === "Delete") {
      remove(languagesEditRef(lang.id));
    } else {
      set(languagesEditRef(lang.id), lang);
    }
  };
  